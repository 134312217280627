// eslint-disable-next-line import/no-absolute-path, import/extensions
import '/app/app/nordic-pages/home/styles.scss';
import { NordicBrowserProviders, getProviders } from 'frontend-page/provider';
import { bootstrapStore } from 'frontend-page/islands-store';
import { lazyHydrate } from 'frontend-lazy/islands';

lazyHydrate({
  islands: new Map([
    ['MenuDesktop', [() => import('/app/app/components/header/desktop/index.js'), 'MenuDesktop']],
    ['HomeShippingCalculatorSection', [() => import('/app/app/sections/home-shipping-calculator-section/index.js'), 'HomeShippingCalculatorSection']],
    ['Testimonials', [() => import('/app/app/components/testimonials/index.js'), 'Testimonials']],
  ]),
  NordicBrowserProviders,
  getProviders,
  bootstrapStore,
});
